import React from 'react';
export interface Props {files : any}
export const MultiAttachedFileBox:React.FC<Props> = ({files}) => {
    // console.log(files)
    const handleDownLoad = (file : any) => {
        const fileUrl: any = file;
        const filePath = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        const downLoad = document.createElement('a');
        downLoad.download = filePath;
        downLoad.href = fileUrl;
        downLoad.target = "_blank";
        document.body.appendChild(downLoad);
        downLoad.click();
        document.body.removeChild(downLoad);
    }
    return(
        <>
        <tr>
            <th className="thumbnail">첨부파일</th>
            <td>
                <ul className='uploadFileList'>
                    <>
                    {files?.length === 0 || files === null ? 
                    <>
                    </> : 
                    <>
                    {files?.map((file:any)=>(
                        <li style={{cursor:'pointer'}} onClick={()=>handleDownLoad(file?.inquiry_pdsFile)}>
                            <span>{file?.inquiry_pdsFilename}</span>
                        </li>
                    ))}
                    </>
                    }
                    </>
                </ul>
            </td>
        </tr>
        </>
    )
}