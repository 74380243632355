import React, { useLayoutEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { NavigationBox } from "../../../../components/subPage/navBox";
import { getCookie } from "../../../../util/cookie/cookies";
import { BussinessOverView } from "./cdmo-bussinessOverview";
import { CDOS } from "./consignamentDevelopment";
import { CMO } from "./consignamentProduction";
import { CDMOQuailty } from "./cdmo-quailty";

export const BioMedicineCDMO = () => {
    const lang = getCookie('BINEXLANG')
    const location = useLocation()
    const searchParam = new URLSearchParams(location.search)
    const tab = searchParam.get('tab') || 1;
    const {thirdUrl} = useParams()
    const [state, setState] = useState<{classname:string;title_kr:string;title_en:string}>({classname : '', title_kr : '',title_en: ''})
    // console.log(state)
    useLayoutEffect(()=>{
        switch(location.pathname){
            case '/bioMedicine/quailty' : setState((prev:any) => ({...prev, classname:'cdmo_service consignment_production quality', title_kr:'Quailty', title_en : 'Quailty'})); break;
            case '/bioMedicine/bussinessOverview': setState((prev)=>({...prev, classname:'business_overview',title_kr:'CDMO 사업개요', title_en : 'CDMO Business'}));break;
            case `/bioMedicine/cdmoservice`: setState((prev)=>({...prev, classname:'cdmo_service consignment_development',title_kr:'CDO (Contract Development Organization)', title_en : 'CDO (Contract Development Organization)'}));break;
            case `/bioMedicine/cdmoproduction`: setState((prev)=>({...prev, classname:'cdmo_service consignment_production',title_kr:'CMO (Contract Manufacturing Organization)', title_en : 'CMO (Contract Manufacturing Organization)'}));break;
        }
    },[location])
    return(
        <>
        <div className={`subPage bio_medicine_cdmo ${state.classname}`}>
            <div className="banner_area">
                <div>
                    <h2>{lang==='Kr'?state.title_kr:state.title_en}</h2>
                </div>
            </div>
            <NavigationBox/>
            {location.pathname === '/bioMedicine/bussinessOverview' ? <BussinessOverView/> :''}
            {location.pathname === `/bioMedicine/cdmoservice` ? <CDOS/> : ''}
            {location.pathname === `/bioMedicine/cdmoproduction` ? <CMO/> : ''}
            {location.pathname === `/bioMedicine/quailty` ? <CDMOQuailty/> : ''}
        </div>
        </>
    )
}