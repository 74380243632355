import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetStockInfomationMutation, useGetStockInforation2Mutation } from "../../../../../store/user/contentsApiSlice";
import Moment from 'react-moment';
import MyResponsiveLine from "../../../../../components/chart/chart";
import MyResponsiveLine2 from "../../../../../components/chart/chart2";
export const StockPrice = () => {
    const {t} = useTranslation()
    const [stockInfo, setStockInfo] = useState<any>([])
    const [dailyPrice, setDailyPrice] = useState<any>([])
    const [fomatData, setFomatData] = useState<any>({
        tradingVolume : [],
        closingPrice : []
    })
    const data1 = [
        {
            "id": "일일거래량",
            "color": "hsl(248, 70%, 50%)",
            "data" : fomatData.tradingVolume
        }
    ]
    const data2 = [
        {
            "id" : "일일종가",
            "color": "hsl(248, 70%, 50%)",
            "data" : fomatData.closingPrice
        }
    ]
    const [getStockInfomationApi] = useGetStockInfomationMutation()
    const [getStockInfomation2Api] = useGetStockInforation2Mutation()
    const getStockInfomation = async() => {
        const result : any = await getStockInfomationApi({})
        if(result.data.success === true){
            setDailyPrice(result.data.results)
            const fomatData = result.data.results.map((list:any)=>({
                "x" : list?.F12507, "y" : parseInt(list?.F15015.replace(/,/g, ""), 10)
            }))
            const fomatData2 = result.data.results.map((list:any)=>({
                "x" : list?.F12507, "y" : parseInt(list?.F15001.replace(/,/g, ""), 10)
            }))
            setFomatData((prev:any)=>({...prev, tradingVolume : fomatData.reverse(), closingPrice : fomatData2.reverse()}))
        }
    }
    const getStockInformation2 = async() => {
        const result : any = await getStockInfomation2Api({})
        if(result.data.success === true){
            setStockInfo(result.data.results)
        }
    }
    useEffect(()=>{
        getStockInfomation()    
        getStockInformation2()
    }, [])
    // console.log(fomatData.tradingVolume)
    return(
        <>
        <div className="contentBox">
            <div>
                <h3><span>{t("invest_stock_price_title")}</span></h3>
                

                <div className="tableWrap">
                    <table>
                        <tbody>
                            <tr>
                                <th>{t("invest_stock_price_1")}</th>
                                <td>{t("invest_stock_price_2")}</td>
                                <th>{t("invest_stock_price_3")}</th>
                                <td>{stockInfo[0]?.F15001}</td>
                            </tr>
                            <tr>
                                <th>{t("invest_stock_price_4")}</th>
                                <td>{stockInfo[0]?.F15472} 
                                {stockInfo[0]?.F15472 <=0 ? 
                                <i className="fa-sharp fa-solid fa-rotate-180 fa-triangle" style={{color: '#0E56A7'}}></i> : 
                                <i className="fa-sharp fa-solid fa-triangle" style={{color: '#E01E25'}}></i>
                                }
                                </td>
                                <th>{t("invest_stock_price_5")}</th>
                                <td>{stockInfo[0]?.F15007}</td>
                            </tr>
                            <tr>
                                <th>{t("invest_stock_price_6")}</th>
                                <td>{stockInfo[0]?.F15015}</td>
                                <th>{t("invest_stock_price_7")}</th>
                                <td>{stockInfo[0]?.F15023}</td>
                            </tr>
                            <tr>
                                <th>{t("invest_stock_price_8")}</th>
                                <td>{stockInfo[0]?.F16046}</td>
                                <th>{t("invest_stock_price_9")}</th>
                                <td>{stockInfo[0]?.F15009}</td>
                            </tr>
                            <tr>
                                <th>{t("invest_stock_price_10")}</th>
                                <td>{stockInfo[0]?.F15010}</td>
                                <th>{t("invest_stock_price_11")}</th>
                                <td>{stockInfo[0]?.F15011}</td>
                            </tr>
                            <tr>
                                <th>{t("invest_stock_price_12")}</th>
                                <td>{stockInfo[0]?.F02133}</td>
                                <th>{t("invest_stock_price_13")}</th>
                                <td>{stockInfo[0]?.F02155}</td>
                            </tr>
                            <tr>
                                <th>{t("invest_stock_price_14")}</th>
                                <td>{stockInfo[0]?.F16117}</td>
                                <th>{t("invest_stock_price_15")}</th>
                                <td>{stockInfo[0]?.F16118}</td>
                            </tr>
                            <tr>
                                <th>PER</th>
                                <td>{stockInfo[0]?.F13058}</td>
                                <th>{t("invest_stock_price_16")}</th>
                                <td>{stockInfo[0]?.F16143}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="graphBox" style={{height : '400px'}}>
                <MyResponsiveLine data={data1}/>
                </div>
                <div className="graphBox" style={{height : '400px'}}>
                <MyResponsiveLine2 data={data2}/>
                </div>

                <div className="tableWrap">
                    <h4>
                    {t("invest_stock_price_17")}
                        <span>{t("invest_stock_price_18")}</span>
                    </h4>
                    <table>
                        <thead>
                            <tr>
                                <th>{t("invest_stock_price_19")}</th>
                                <th>{t("invest_stock_price_20")}</th>
                                <th>{t("invest_stock_price_21")}</th>
                                <th>{t("invest_stock_price_22")}</th>
                                <th>{t("invest_stock_price_23")}</th>
                                <th>{t("invest_stock_price_24")}</th>
                                <th>{t("invest_stock_price_25")}</th>
                                <th>{t("invest_stock_price_26")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dailyPrice?.map((list:any, index : number)=>{
                                return(
                                <tr key={index}>
                                    <td>{list?.F12507}</td>
                                    <td>{list?.F15001}</td>
                                    <td>
                                        {list?.F15472 <= 0 ? 
                                        <i className="fa-sharp fa-solid fa-rotate-180 fa-triangle" style={{color: '#0E56A7'}}></i> :
                                        <i className="fa-sharp fa-solid fa-triangle" style={{color: '#E01E25'}}></i> }
                                    {list?.F15472}</td>
                                    <td>{list?.F15009}</td>
                                    <td>{list?.F15010}</td>
                                    <td>{list?.F15011}</td>
                                    <td>{list?.F15015}</td>
                                    <td>{list?.F15028}</td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
        </>
    )
}

