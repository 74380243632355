import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../../../util/cookie/cookies";

export const History = () => {
    const scrollRef = useRef<any>([])
    const [state, setState] = useState<number>(1)
    const {t} = useTranslation()
    const lang = getCookie('BINEXLANG')
    // useEffect(() => {
    //     if(scrollRef.current[0]){console.log("1")}
    //     else if(scrollRef.current[1]){console.log("1")}
    //   }, [scrollRef]); 
    return(
        <>
        <div className="contentBox">
            <div>
                {lang === 'Kr' ? 
                <h3>
                    차세대 생명공학의 선두주자가 되기 위한
                    <span> 바이넥스의 발자취를 확인하세요.</span>
                </h3>
                : 
                <h3><span>Check out Binex's footsteps</span> toward becoming a leading pioneer in next-generation biotechnology</h3>
                }

                
                <div className="tabBtnBox">
                    <div>
                        <div onClick={()=>setState(1)}>
                            <button className={state===1?'active':''}>2023 - 2019</button>
                        </div>
                        <div onClick={()=>setState(2)}>
                            <button className={state===2?'active':''}>2018 - 2011</button>
                        </div>
                        <div onClick={()=>setState(3)}>
                            <button className={state===3?'active':''}>2010 - 2005</button>
                        </div>
                    </div>
                </div>

                <div className="tabContentBox">
                    <div className={state===1?'active':''}>
                        <ul>
                            <li id="2023" ref={e=>scrollRef.current[0]=e}>
                                <span>2023</span>
                                <p>
                                    <span>10</span>
                                    {t("history_2023_1")}
                                </p>
                                <p>
                                    <span>04</span>
                                    {t("history_2023_2")}
                                </p>
                                <p>
                                    <span>03</span>
                                    {t("history_2023_3")}
                                </p>
                            </li>
                            <li id="2022" ref={e=>scrollRef.current[0]=e}> 
                                <span>2022</span>
                                <p>
                                    <span>11</span>
                                    {t("history_2022_1")}
                                </p>
                                <p>
                                    <span>09</span>
                                    {t("history_2022_2")}
                                </p>
                                <p>
                                    <span>04</span>
                                    {t("history_2022_3")}
                                </p>
                                <p>
                                    <span>01</span>
                                    {t("history_2022_4")}
                                </p>
                            </li>
                            <li id="2021">
                                <span>2021</span>
                                <p>
                                    <span>11</span>
                                    {t("history_2021_1")}
                                </p>
                                <p>
                                    <span>03</span>
                                    {t("history_2021_2")}
                                </p>
                            </li>
                            <li id="2020">
                                <span>2020</span>
                                <p>
                                    <span>06</span>
                                    {t("history_2020_1")}
                                </p>
                                <p>
                                    <span>09</span>
                                    {t("history_2020_2")}
                                </p>
                                <p>
                                    <span>11</span>
                                    {t("history_2020_3")}
                                </p>
                            </li>
                            <li id="2019">
                                <span>2019</span>
                                <p>
                                    <span>12</span>
                                    {t("history_2019_1")}
                                </p>
                                <p>
                                    <span>09</span>
                                    {t("history_2019_2")}
                                </p>
                                <p>
                                    <span>07</span>
                                    {t("history_2019_3")}
                                </p>
                            </li>
                        </ul>
                    </div>
                    {/* <!-- 2023 - 2019 Fin --> */}
                    
                    <div className={state===2?'active':''}>
                        <ul>
                            <li>
                                <span>2018</span>
                                <p>
                                    <span>10</span>
                                    {t("history_2018_1")}
                                </p>
                                <p>
                                    <span>05</span>
                                    {t("history_2018_2")}
                                </p>
                                <p>
                                    <span>02</span>
                                    {t("history_2018_3")}
                                </p>
                            </li>
                            <li>
                                <span>2017</span>
                                <p>
                                    <span>10</span>
                                    {t("history_2017_1")}
                                </p>
                                <p>
                                    <span>02</span>
                                    {t("history_2017_2")}
                                </p>
                                <p>
                                    <span>02</span>
                                    {t("history_2017_2_1")}
                                </p>
                                <p>
                                    <span>01</span>
                                    {t("history_2017_3")}
                                </p>
                            </li>
                            <li>
                                <span>2016</span>
                                <p>
                                    <span>11</span>
                                    {t("history_2016_1")}
                                </p>
                                <p>
                                    <span>08</span>
                                    {t("history_2016_2")}
                                </p>
                                <p>
                                    <span>06</span>
                                    {t("history_2016_3")}
                                </p>
                            </li>
                            <li>
                                <span>2015</span>
                                <p>
                                    <span>09</span>
                                    {t("history_2015_1_1")}
                                </p>
                                <p>
                                    <span>09</span>
                                    {t("history_2015_1_2")}
                                </p>
                            </li>
                            <li>
                                <span>2014</span>
                                <p>
                                    <span>10</span>
                                    {t("history_2014_1")}
                                </p>
                            </li>
                            <li>
                                <span>2013</span>
                                <p>
                                    <span>10</span>
                                    {t("history_2013_1")}
                                </p>
                            </li>
                            <li>
                                <span>2012</span>
                                <p>
                                    <span>06</span>
                                    {t("history_2012_1")}
                                </p>
                                <p>
                                    <span>06</span>
                                    {t("history_2012_1_1")}
                                </p>
                                <p>
                                    <span>05</span>
                                    {t("history_2012_2")}
                                </p>
                            </li>
                            <li>
                                <span>2011</span>
                                <p>
                                    <span>07</span>
                                    {t("history_2011_1")}
                                </p>
                            </li>
                        </ul>
                    </div>
                    {/* <!-- 2018 - 2011 Fin --> */}
                    
                    <div className={state===3?'active':''}>
                        <ul>
                            <li>
                                <span>2010</span>
                                <p>
                                    <span>06</span>
                                    {t("history_2010_1")}
                                </p>
                                <p>
                                    <span>03</span>
                                    {t("history_2010_2")}
                                </p>
                            </li>
                            <li>
                                <span>2009</span>
                                <p>
                                    <span>11</span>
                                    {t("history_2009_1")}
                                </p>
                                <p>
                                    <span>03</span>
                                    {t("history_2009_2")}
                                </p>
                                <p>
                                    <span>02</span>
                                    {t("history_2009_3")}
                                </p>
                            </li>
                            <li>
                                <span>2008</span>
                                <p>
                                    <span>10</span>
                                    {t("history_2008_1")}
                                </p>
                                <p>
                                    <span>06</span>
                                    {t("history_2008_2")}
                                </p>
                            </li>
                            <li>
                                <span>2007</span>
                                <p>
                                    <span>11</span>
                                    {t("history_2007_1")}
                                </p>
                                <p>
                                    <span>09</span>
                                    {t("history_2007_2")}
                                </p>
                                <p>
                                    <span>09</span>
                                    {t("history_2007_2_2")}
                                </p>
                                <p>
                                    <span>08</span>
                                    {t("history_2007_3")}
                                </p>
                                <p>
                                    <span>06</span>
                                    {t("history_2007_4")}
                                </p>
                                <p>
                                    <span>06</span>
                                    {t("history_2007_4_2")}
                                </p>
                            </li>
                            <li>
                                <span>2006</span>
                                <p>
                                    <span>12</span>
                                    {t("history_2006_1")}
                                </p>
                                <p>
                                    <span>12</span>
                                    {t("history_2006_1_1")}
                                </p>
                                <p>
                                    <span>11</span>
                                    {t("history_2006_2")}
                                </p>
                                <p>
                                    <span>09</span>
                                    {t("history_2006_3")}
                                </p>
                                <p>
                                    <span>04</span>
                                    {t("history_2006_4")}
                                </p>
                                <p>
                                    <span>03</span>
                                    {t("history_2006_5")}
                                </p>
                            </li>
                            <li>
                                <span>2005</span>
                                <p>
                                    <span>11</span>
                                    {t("history_2005_1")}
                                </p>
                                <p>
                                    <span>07</span>
                                    {t("history_2005_2")}
                                </p>
                                <p>
                                    <span>07</span>
                                    {t("history_2005_2_1")}
                                </p>
                                <p>
                                    <span>05</span>
                                    {t("history_2005_3")}
                                </p>
                                <p>
                                    <span>04</span>
                                    {t("history_2005_4")}
                                </p>
                            </li>
                        </ul>
                    </div>
                    {/* <!-- 2010 - 2005 Fin --> */}
                </div>

                <span>* QP :  Qualified person</span>
                <span>* PMDA: Pharmaceuticals and Medical Devices Agency (Japan)</span>
                <span>* TMMDA : Turkish Medicines and Medical Devices Agency</span>
            </div>
        </div>
        </>
    )
}